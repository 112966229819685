import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import createRoutes from 'root-views/main-routes'
import { ThemeProvider } from '@mui/material/styles'
import { ThemeProvider as StylesThemeProvider } from '@mui/styles'
import { theme } from 'root-utils/constants'
import { Provider } from 'react-redux'
import Reducers from 'root-states'
import { createStore } from 'redux'
import Authentication from 'root-resources/oauth/authentication'
import userLangController from '@bubotech/sumora-react-components/lib/utils/language/user-lang-controller'
import { registerLicense } from '@syncfusion/ej2-base';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

userLangController.setUserLang('pt-BR')

Authentication.initInterceptors()
registerLicense(process.env.REACT_APP_SYNC_FUSION_LICENSE ?? "");

const routes = createRoutes()
const store = createStore(Reducers)

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <StylesThemeProvider theme={theme}>
      <Provider store={store}>{routes}</Provider>
    </StylesThemeProvider>
  </ThemeProvider>,
  document.getElementById('root')
)
