import { createTheme } from '@mui/material/styles'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

declare module '@mui/material/styles' {
  interface Palette {
    brown: Palette['primary'];
    turquoise: Palette['primary'];
    purple: Palette['primary'];
  }

  interface PaletteOptions {
    brown?: PaletteOptions['primary'];
    turquoise?: PaletteOptions['primary'];
    purple?: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 360,
      sm: 450,
      md: 600,
      lg: 900,
      xl: 1440,
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRight: 'none',
          background: '#FFF',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '3px 0'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0px !important'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 13
        }
      }
    },
    MuiInputBase: {
      defaultProps: {
        rows: 3,
      },
      styleOverrides: {
        root: {
          height: 34,
          padding: 6,
          backgroundColor: 'rgba(239, 243, 248, 0.5)',
          '&:not(.Mui-disabled):hover': {
            backgroundColor: 'rgba(239, 243, 248, 1)',
          },
          '&.MuiInput-underline': {
            '&:before': {
              borderBottom: '1px solid #C3D2E3',
            },
            '&:not(.Mui-disabled):hover:before': {
              borderBottom: '1px solid #95A2B9',
              borderBottomWidth: '1px',
            },
            '&:after': {
              borderBottomWidth: '1px',
            },
          },
          '&.Mui-disabled': {
            opacity: 0.85,
          },
          '&.Mui-focused': {
            '&.select': {
              backgroundColor: '#33384F',
              color: '#C3D2E3',
            },
            backgroundColor: 'rgba(239, 243, 248, 0.5)',
            '&:after': {
              borderBottomWidth: '1px',
            },
          },
        },
        multiline: {
          height: 89,
          padding: 6,
          display: 'flex',
          alignItems: 'flex-start',
          '&.MuiInputBase-adornedEnd': {
            display: 'hidden',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 400,
          letterSpacing: 0,
          color: '#33384F !important',
          opacity: 0.5,
          zIndex: 5,
          padding: 4,
          fontFamily: 'Open Sans',
          '&.MuiInputLabel-shrink': {
            padding: 0,
            fontSize: '13px',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          color: '#FFFFFF',
        },
        track: {
          backgroundColor: '#C3D2E3',
          opacity: '1 !important',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          fontSize: 9,
          color: '#95A2B9',
          textAlign: 'right',
          width: 35,
          height: 15,
          right: 42,
          top: 35,
        },
        h4: {
          fontSize: 14,
          fontWeight: 'bold',
          lineHeight: '19px',
          color: '#5B6478',
          marginBottom: 25
        },
        root: {
          fontFamily: 'Open Sans',
          fontSize: 14,
          fontWeight: 400,
        },
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <CloseRoundedIcon className='chip-delete-icon' />,
      },
      styleOverrides: {
        root: {
          border: '1px solid #95A2B9',
          borderRadius: 15,
          height: 29,
          background: '#FFF',
          margin: '0px 12px 6px 0px',
        },
        deleteIcon: {
          height: 15,
          width: 15,
          marginRight: 10,
          color: '#33384F !important',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:not(.Mui-expanded)': {
            borderBottomColor: 'transparent',
          },
          '&.Mui-expanded': {
            borderBottom: '1px solid #C3D2E3',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #EFF3F8',
          padding: '0px 25px',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: (props: any) => <KeyboardArrowDownIcon {...props} />,
      },
      styleOverrides: {
        iconOpen: {
          color: '#C3D2E3',
        },
        icon: {
          padding: '0 14px'
        }
      },
    },
    MuiSpeedDialAction: {
      styleOverrides: {
        fab: {
          backgroundColor: '#5C78C7',
          color: '#FFF',
          ":hover": {
            backgroundColor: '#5C78C7'
          }
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          paddingRight: 0,
          marginRight: 0,
        },
      },
    },
  },
  palette: {
    brown: {
      main: '#4A0707'
    },
    purple: {
      main: '#5F0075'
    },
    turquoise: {
      main: '#0DB990',
    },
    primary: {
      main: '#223771',
      light: '#4AA4E1',
      dark: '#5C78C7',
      "100": '#1F497E',
      '600': '#1182a5'
    },
    secondary: {
      main: '#FFFFFF',
      light: '#E6F8FD',
    },
    common: {
      white: '#FFFFFF',
      'black': '#000',
    },
    warning: {
      main: '#E28800',
      light: '#EC9C35'
    },
    info: {
      main: '#FFFFFF',
      contrastText: '#8a5ab4',

    },
    error: {
      main: '#F7375C',  
      "500": '#C80000',
      '600': '#B90D0D'
    },
    background: {
      default: '#EFF3F8',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.7)',
      secondary: '#5B6478',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    grey: {
      "100": '#C3D2E3',
      "300": '#95A2B9',
      "500": '#5B6478',
      "700": '#33384F',
      "800": '#7B7B7B',
      "900": "#707070",
    },

    success: {
      main: '#55B259',
      light: '#53C332',
      "500": "#047E00",
      "700": '#007E57',
    },
  },
});

const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

export { theme, months }
