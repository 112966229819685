import React from 'react';
import { useStyles } from './grid-card.styles';

interface GridCardTextProps {
  text: string;
}

function GridCardContent(props: GridCardTextProps & React.HTMLAttributes<HTMLParagraphElement>): JSX.Element {
  const { text, ...rest } = props;
  const classes = useStyles();

  return <p className={classes.text} {...rest}>{text}</p>
}

export default GridCardContent;