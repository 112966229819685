import { SweetAlertResult } from "sweetalert2";
import { Swal } from "..";
import MensagemErro from "root-models/error";

/**
 * Renderiza um swal de erro para uma requisição para api
 * @param err Erro
 * @param errorMsg Mensagem
 * @param errorTitle Título 
 */
function ApiErrorSwal(err: any, errorMsg?: string, errorTitle?: string, handleOption?: (option: SweetAlertResult<any>) => void) {
  let error: MensagemErro = {...err?.response?.data, error: true};

  Swal({
    showConfirmButton: true,
    title: error?.titulo || errorTitle || 'Erro',
    text: error?.mensagem ?? errorMsg,
    icon: 'error'
  }).then((opt) => handleOption?.(opt));
}

export default ApiErrorSwal;